import { WrenchScrewdriverIcon } from '@heroicons/react/24/outline'

export default function DevelopmentBanner() {
	if (process.env.AWS_BRANCH === 'main') {
		// eslint-disable-next-line unicorn/no-null
		return null
	} else {
		return (
			<div className="bg-tmpinkdark">
				<div className="mx-auto max-w-7xl px-3 py-3 sm:px-6 lg:px-8">
					<div className="flex flex-wrap items-center justify-between">
						<div className="flex w-0 flex-1 items-center">
							<span className="flex rounded-lg p-2">
								<WrenchScrewdriverIcon className="h-6 w-6 text-white" aria-hidden="true" />
							</span>
							<p className="ml-3 truncate font-medium text-white">Development Environment ({process.env.AWS_BRANCH ?? 'unknown'})</p>
						</div>
					</div>
				</div>
			</div>
		)
	}
}
