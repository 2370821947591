import { AddToAppleWalletButton } from '@/components/AddToAppleWalletButton'
import { AddToGoogleWalletButton } from '@/components/AddToGoogleWalletButton'
import { BecomeMember } from '@/components/BecomeMember'

import { useState } from 'react'

export function Hero() {
	const [isAddedToWallet, setIsAddedToWallet] = useState(false)

	return (
		<div className="mx-auto max-w-7xl px-4 pb-20 pt-10 text-center sm:px-6 lg:px-8">
			{isAddedToWallet ? (
				<div className="mt-10">
					<h2 className="text-4xl font-semibold text-tmpinkdark">One more thing!</h2>
					<p className="mt-6 text-lg tracking-tight text-tmcosmic">Double-click the side button (home button on older devices) of your iPhone and tap the green terminal to register your stamp.</p>
				</div>
			) : (
				<div>
					<h1 className="mx-auto max-w-3xl font-display text-4xl font-medium tracking-tight text-tmpinkdark sm:text-7xl">
						We can see{' '}
						<span className="relative whitespace-nowrap">
							<span className="relative">FREE ☕️</span>
						</span>{' '}
						in your future.
					</h1>
					<p className="mx-auto mt-6 max-w-lg text-lg tracking-tight text-tmcosmic">Add the Talormade loyalty card to your digital wallet, and get a free coffee with every 6th purchase!</p>
					<div className="-mb-14 flex justify-center">
						<BecomeMember className="fill-tmpinkdark" />
					</div>
					<div className="mt-10 flex justify-center gap-x-6">
						<AddToAppleWalletButton setIsAddedToWallet={setIsAddedToWallet} />
						<AddToGoogleWalletButton />
					</div>
				</div>
			)}
		</div>
	)
}
