import Link from 'next/link'

export function Footer() {
	return (
		<footer className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
			<div className="flex flex-col items-center py-10 sm:flex-row-reverse sm:justify-between">
				<p className="mt-6 text-sm text-slate-500 sm:mt-0">
					Powered by <Link href="https://getstell.com">Stell</Link>. Made in Norway 🇳🇴
				</p>
				<Link className="mt-6 text-sm text-slate-500 sm:mt-0" href="https://talormade.no">
					Talormade
				</Link>
			</div>
		</footer>
	)
}
