import { Logo } from '@/components/Logo'
import Link from 'next/link'

export function Header() {
	return (
		<header className="mx-auto max-w-7xl px-4 py-10 sm:px-6 lg:px-8">
			<nav className="relative z-50 flex justify-between">
				<div className="flex items-center md:gap-x-12">
					<Link href="/" aria-label="Home" title="Talormade logo">
						<Logo className="h-10 w-auto" />
					</Link>
				</div>
			</nav>
		</header>
	)
}
