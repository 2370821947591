/* This example requires Tailwind CSS v2.0+ */
import handImage from '@/images/hand.png'
import { DevicePhoneMobileIcon, GiftIcon, WalletIcon } from '@heroicons/react/24/outline'

import Image from 'next/image'

const steps = [
	{
		id: 1,
		name: 'Get Started',
		description: 'Add the Talormade loyalty card to your digital wallet of choice. We support both Apple Wallet (iPhone and Apple Watch) and Google Wallet.',
		icon: WalletIcon,
	},
	{
		id: 2,
		name: 'All it takes is a tap',
		description: 'Tap your phone to the triangle shaped reader on the counter to register your stamp. Your loyalty card is automatically selected, you just have to confirm with FaceID.',
		icon: DevicePhoneMobileIcon,
	},
	{
		id: 3,
		name: 'Automatic rewards',
		description: 'For every 6th stamp you get a free coffe. You loyalty card is being automatically reset so you can start over again.',
		icon: GiftIcon,
	},
]
export function Feat() {
	return (
		<div className="overflow-hidden bg-tmblue py-0">
			<div>
				<svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" height={80} preserveAspectRatio="none" className="w-full fill-tmpink">
					<path d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z" />
				</svg>
			</div>
			<div className="relative mx-auto max-w-xl px-4 sm:px-6 lg:max-w-7xl lg:px-12">
				<div className="relative mt-8 lg:mt-8 lg:grid lg:grid-cols-2 lg:items-center lg:gap-8">
					<div className="relative">
						<dl className="mt-10 space-y-10">
							{steps.map((item) => (
								<div key={item.id} className="relative">
									<dt>
										<div className="absolute flex h-12 w-12 items-center justify-center rounded-md bg-tmpinkdark text-tmpink">
											<item.icon className="h-6 w-6" aria-hidden="true" />
										</div>
										<p className="ml-16 text-lg font-medium leading-6 text-tmpink">{item.name}</p>
									</dt>
									<dd className="ml-16 mt-2 text-base text-tmpink">{item.description}</dd>
								</div>
							))}
						</dl>
					</div>

					<div className="relative -mx-4 mt-10 lg:mt-0" aria-hidden="true">
						<Image src={handImage} alt="Picture of Talormade loyalty card on an iPhone" className="relative mx-auto" width={450} height={705} />
					</div>
				</div>
			</div>
		</div>
	)
}
