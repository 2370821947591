import DevelopmentBanner from '@/components/DevelopmentBanner'
import { Feat } from '@/components/Feat'
import { Footer } from '@/components/Footer'
import { Header } from '@/components/Header'
import { Hero } from '@/components/Hero'
import Head from 'next/head'

export default function Home() {
	return (
		<>
			<Head>
				<title>Talormade Loyalty Card</title>
				<meta name="description" content="Talormade Loyalty Card" />
			</Head>
			<DevelopmentBanner />
			<Header />
			<main>
				<Hero />
				<Feat />
			</main>
			<Footer />
		</>
	)
}
