import { SVGProps } from 'react'

export function Logo(properties: JSX.IntrinsicAttributes & SVGProps<SVGSVGElement>) {
	return (
		<svg aria-hidden="true" viewBox="0 0 118 60" {...properties}>
			<g>
				<path
					d="M117.6 58.8h-10.1V18.2c0-5-2.9-8.3-7.8-8.3-4.2 0-6.3 1.9-8 5.1l-2.1 3.6 5.5 11.2c3 6.1 4.3 11.2 4.3 15.6 0 9.5-6.3 14.7-15.3 14.7s-15.5-5.2-15.5-14.5c0-4.4 1.4-9.6 4.5-15.8 1.7-3.4 3.7-7.3 5.7-11.1l-3.5-6.8-20.3 47H44L69.1 1.4h12.2l3.4 7C87.9 3.1 93.6 0 99.8.2c12 0 17.9 8.1 17.9 18.8v39.8zM84.2 29.2L82.4 33c-1.6 3.6-3.3 8.3-3.3 12.2 0 3 1.8 5.3 5 5.3s5.1-2.3 5.1-5.3c0-3.9-1.6-8.7-3.2-12.2l-1.8-3.8zM57 11.6c-1.9-1-4-1.6-6.1-1.6-3.9 0-7.7 2-12.5 5.4v43.4H28.3V22.4c-3.3 1.9-7 2.9-10.9 2.9C7 25.3.4 20.4.4 12.8.4 5.8 7.2.2 18 .2c6 0 11.7 2.1 16.4 5.8C39.7 2.1 45.1 0 50 0c2.4 0 4.8.5 7 1.5v10.1zm-45.9 1.2c0 1.8 2.2 3.1 6.5 3.1 3.2 0 6.3-1.2 8.8-3.2-2.2-2.2-6-3.1-8.6-3.1-4.3 0-6.7 1.4-6.7 3.2z"
					fill="#fff"
				/>
			</g>
			<g>
				<path
					fill="#d61d64"
					d="M99.7.2c-6.1-.2-11.9 3-15.1 8.2l-3.4-7H69.1L44 58.8h11.1l20.3-47 3.5 6.8c-2 3.8-4 7.7-5.7 11.1-3.1 6.1-4.5 11.4-4.5 15.8 0 9.3 6.5 14.5 15.5 14.5s15.2-5.2 15.3-14.7c0-4.4-1.3-9.6-4.3-15.6l-5.5-11.2 2.1-3.6c1.7-3.1 3.8-5 8-5 4.9 0 7.8 3.3 7.8 8.3v40.6h10.1V19c-.1-10.7-6-18.8-18-18.8zM84.1 50.4c-3.2 0-5-2.3-5-5.3 0-3.8 1.7-8.6 3.3-12.2l1.8-3.8 1.8 3.8c1.6 3.4 3.2 8.3 3.2 12.2 0 3-1.9 5.3-5.1 5.3z"
				/>
				<path
					fill="#d61d64"
					d="M57 11.6V1.4c-2.2-1-4.6-1.5-7-1.5-5 0-10.3 2.1-15.7 6C29.7 2.2 24 .2 18 .2 7.2.2.4 5.8.4 12.8c0 7.6 6.6 12.5 17.1 12.5 3.8 0 7.6-1 10.9-2.9v36.4h10.1V15.4C43.2 12 47 10 50.9 10c2.1 0 4.2.6 6.1 1.6zm-39.4 4.3c-4.3 0-6.5-1.4-6.5-3.1 0-1.8 2.4-3.1 6.7-3.1 2.7 0 6.4.8 8.7 3.1-2.6 2-5.7 3.1-8.9 3.1z"
				/>
			</g>
		</svg>
	)
}
