import { SVGProps } from 'react'

export function BecomeMember(properties: JSX.IntrinsicAttributes & SVGProps<SVGSVGElement>) {
	return (
		<svg aria-hidden="true" height="150" viewBox="0 0 1050 350" {...properties}>
			<path
				d="M733 246.2L739.1 241.3C745.2 247.2 747.6 256.3 745.2 262.7L777 222L782.1 226L750.3 266.6C755.9 262.7 765.2 262.8 772.5 267.3L769.1 274.4C759.2 267.4 749.5 268.9 742.7 276.3L737.6 272.3C743.1 263.9 742.2 254.1 733 246.2Z"
				fill="fill-inherit"
				className="animate-pulse"
			/>
			<path
				d="M243.4 276L241 272.9C244.1 269.9 248.7 268.9 251.8 270.2L232 253.5L234.1 251L253.9 267.6C252.1 264.7 252.3 260.1 254.7 256.5L258.2 258.3C254.5 263.1 255.1 268 258.7 271.6L256.6 274.1C252.4 271.2 247.5 271.5 243.4 276Z"
				fill="fill-inherit"
				className="animate-pulse"
			/>
			<path
				d="M178 147.7C168.4 160.4 157.5 147.1 152.5 153.6C147.4 160.3 159 163.9 151.9 173.4C146.5 180.5 139.5 179 136.1 176.5C130.1 172 132.7 167.3 130.1 165.3C125.8 162 124.6 166.6 121.1 163.9L112 157L151.4 104.8L160.6 111.7C159.4 113.2 158.9 115.9 161.1 117.5C164.2 119.9 167.9 115.9 176.8 122.7C188.2 131.3 182.1 142.3 178 147.7ZM142.8 168.1C146 163.9 139.2 161.4 143.9 153.3C146.2 149.4 146.1 147.4 143 145.1C140.6 143.3 136.9 142.8 134.5 145.9L129.2 152.9C132.7 153.8 134.4 155.7 133.2 159.4C132.1 162.8 132.8 166.1 135.8 168.4C138.2 170.1 140.3 171.4 142.8 168.1ZM164.1 139.3C167.9 134.8 170.9 137.4 173.6 134C175.9 131.1 174 127.3 171 125.1C168.2 123.1 163.1 123.1 160.3 128.1C156.7 134.6 150.6 132.6 147.9 128L145.5 131.2C141.1 137 146.4 143.1 149.4 145.4C153.2 148.3 155.6 148.6 157.9 147.1C161.2 145 160.5 143.5 164.1 139.3Z"
				fill="fill-inherit"
			/>
			<path d="M190.6 133.8L226.8 153L221.7 162.8L195.7 149L188.1 163.6L214.1 177.4L209.1 187.2L183.1 173.4L175.4 188.1L201.4 201.9L196.3 211.6L160.1 192.4L190.6 133.8Z" fill="fill-inherit" />
			<path
				d="M221.4 175.1C222.7 171.4 227.5 168.9 232.2 169.6C238.1 170.5 244 169.8 246.2 165.9C248.7 161.5 253 160.9 257.6 162.5C265.6 165.3 262.7 171.5 264.9 177.5C267.7 185.4 275.7 184 272.3 193.7C271.1 197.3 267.4 199.2 263.1 197.6C257.8 195.7 256.4 189.8 257.3 186.5C258.3 183.2 260.5 175.3 252.2 172.3C249.1 171.2 242.3 171.8 240.7 176.4C239.6 179.5 241.2 182.3 239.5 187C237.4 192.8 230.5 189.8 228.7 195.1C227.7 198.4 233.3 200 233 203.9C232.5 209.9 226.4 214.4 234.8 217.4C239.5 219.1 243.5 217.9 245.5 215C249.5 209.2 254.8 208.8 258.4 210.1C262.1 211.4 265.5 215 264 219.3C261.7 226 255.9 226.6 252.9 225.4C249.4 224.1 248.1 221.6 243.5 222.5C238.3 223.4 238 229.3 230.8 226.8C223.6 224.3 225.6 217.6 223.2 214.2C220.7 210.7 218.2 210.6 217.7 205.4C217.3 200.8 219.9 199.2 221.8 192.2C223 187.5 218.9 182.4 221.4 175.1Z"
				fill="fill-inherit"
			/>
			<path
				d="M276.8 200.4C278.9 193.9 282.3 195.6 287.4 190.8C292.3 186 294.2 173.6 308.4 176.2C316.3 177.6 317.6 181.9 320.1 189.3C322.2 195.3 329.6 193.6 332.1 198.8C333.7 202.2 333.9 205.4 333.3 208.5C332.5 213.2 330.5 215.9 329.6 220.7C327 235.3 316.2 226.9 314.8 234.5C313.1 244.1 305.4 245 295.9 243.2C291.8 242.5 284.7 239.2 283.3 234.8C281.3 228.8 272.8 228.6 274.2 220.6C274.6 218.1 276 216.7 276.6 212.6C277.7 206.8 275.5 204.5 276.8 200.4ZM290.8 206.1C290 210.7 286.4 211.7 285.8 215C284.9 220.2 290.3 217.9 290.4 225.2C290.4 227.6 290.7 235.6 298.8 237.1C302.5 237.8 306.4 236.3 307.8 228.4C308.6 223.7 316.1 223.1 319.6 225.3C322.8 227.3 325.6 224.6 326.4 220.2C327.5 212.4 314 214.5 315.8 203.5C317.4 193.4 315.1 188.5 305.4 186.7C299.7 185.7 294.1 188.3 292.1 193.1C289.7 198.6 291.8 200.4 290.8 206.1Z"
				fill="fill-inherit"
			/>
			<path
				d="M391.1 215.7C391.2 212 391 207.5 385.5 207.4C381.8 207.3 378.8 210.4 379.1 213.9C380 223.1 387.2 219.5 387 227.2C387 229.3 385 231.5 383 231.4C378.3 231.3 376.9 233.4 376 237.4C375 241.9 370.9 243.3 367.3 243.2C362.5 243.1 355.6 237.6 359.5 229C362.3 222.9 362.8 215.4 356.1 215.3C352.9 215.2 351.2 218.3 351.1 222.4L350.6 249.6L339.2 249.4L340.5 183.7L351.8 183.9C350.9 188.6 352.7 192 356.1 192.1C359.1 192.2 363.6 191 364.9 194.3C366.6 198.6 360.6 199.2 360.5 203.6C360.4 208.8 370.5 206.5 370.4 213C370.3 217.8 366.1 218.4 366 221.5C365.9 224.9 368.1 226.7 371.4 226.8C375.2 226.9 376.8 224.1 377 221.7C377.2 219.2 377 216.6 374.8 213.9C371.7 210.2 370.4 207.4 370.5 201.7C370.6 197 374.2 192.2 381.7 192.4C387 192.5 391.5 193.5 391.6 184.8L402.9 185L401.7 250.7L390.3 250.5L391.1 215.7Z"
				fill="fill-inherit"
			/>
			<path d="M409 183.3L449.9 178.8L451.1 189.7L421.7 193L423.5 209.3L452.9 206.1L454.1 217L424.7 220.3L426.6 236.8L455.9 233.5L457.1 244.4L416.2 249L409 183.3Z" fill="fill-inherit" />
			<path
				d="M561 212.9C562.3 219.1 557 221.5 554.7 222C551.7 222.7 546 222.1 544.6 216.3C542.6 208.5 549.5 205.7 548 200.1C546.8 195.8 544 192.6 540.8 193.5C533.5 195.5 538 201.9 532.3 203.4C527.6 204.7 526.2 200.5 521.8 201.7C519.3 202.4 519.4 204.4 519.7 205.4C520.8 209.3 526.7 207.4 528.2 213C529.8 218.9 522 218.1 525.1 223.8C527.1 227.4 525.6 230.6 520.5 232C515.3 233.4 512.7 230.6 511.6 225.8C510.4 220.1 518.7 218.5 517.3 212.4C515.6 205.3 511.5 207 509.9 201.3C507.2 192.1 522.7 191.4 520.8 184.4C519.6 180.1 514.8 181.4 512.8 174.1C511.2 168.5 515 163.7 521.7 161.8C536.1 157.8 536.2 170.5 539.7 177.2C542.8 183.3 552.2 181.7 553.7 187.9C554.9 193 551.4 192.3 553.2 199.6C554.1 203.9 559.6 205.8 561 212.9ZM527.7 171.8C524.5 172.4 522.4 175.1 523.2 178C524.4 182.4 526.4 181.4 527.3 184.6C528 187.1 526.8 189.3 526.8 189.3L534.9 187.1C533.2 184.7 534.1 183 534.6 181C536.3 174.3 530.9 171.2 527.7 171.8Z"
				fill="fill-inherit"
			/>
			<path
				d="M595.6 140.6L606.7 138.1L615.5 179.1L622.1 134.7L635.4 131.7L644.2 172.7L650.9 128.3L664.4 125.2L678.4 189.9L667.5 192.3L658.1 149L650.7 196L638.8 198.7L629.5 155.3L621.8 202.5L609.5 205.2L595.6 140.6Z"
				fill="fill-inherit"
			/>
			<path d="M684.1 124L725.2 122.1L725.7 133L696.2 134.4L697 150.7L726.5 149.3L727 160.2L697.5 161.6L698.2 178L727.8 176.7L728.3 187.6L687.1 189.5L684.1 124Z" fill="fill-inherit" />
			<path d="M790.5 192.9L779.5 191.4L786.2 142.3L771.1 166L759.1 164.4L750.9 137.6L744.3 186.7L733.2 185.2L742 120.5L757.8 122.6L766.8 152.7L783.5 126.1L799.3 128.2L790.5 192.9Z" fill="fill-inherit" />
			<path
				d="M855.8 165.8C851 181 836.3 172.1 833.7 179.9C831.1 188 843.3 187.5 839.7 198.9C837 207.4 829.9 208.4 825.9 207.1C818.8 204.8 819.6 199.5 816.5 198.5C811.4 196.8 811.8 201.6 807.6 200.2L796.7 196.7L816.5 134L827.5 137.5C826.9 139.3 827.3 142 829.9 142.9C833.6 144.1 835.7 139.2 846.4 142.6C860 146.9 857.9 159.3 855.8 165.8ZM829.5 197C831.1 192 823.9 191.9 825.6 182.6C826.4 178.2 825.8 176.3 822 175.1C819.2 174.2 815.5 174.9 814.3 178.7L811.7 187.1C815.3 186.8 817.5 188 817.7 192C817.7 195.6 819.5 198.5 823.1 199.6C825.9 200.4 828.2 200.9 829.5 197ZM840 162.5C842.1 156.9 845.7 158.5 847.2 154.3C848.4 150.7 845.3 147.8 841.8 146.8C838.5 145.8 833.7 147.5 832.7 153.3C831.5 160.7 825.1 160.8 821 157.4L819.8 161.2C817.6 168.2 824.6 172.2 828.2 173.4C832.8 174.9 835.1 174.3 836.7 172.2C839.1 168.9 838 167.7 840 162.5Z"
				fill="fill-inherit"
			/>
			<path d="M874.4 153.9L911.8 170.5L907.3 180.4L880.4 168.5L873.7 183.2L900.6 195.1L896 205L869.1 193L862.4 207.8L889.2 219.8L884.7 229.6L847.3 213L874.4 153.9Z" fill="fill-inherit" />
			<path
				d="M926.3 253.3C929.3 250 931.5 246.9 933.1 244C935.9 239.1 937.1 234.8 937.1 231.1C929 236.7 922 234.1 919.1 232.5C914.7 230 911.8 226 911.4 221.5L901.3 239.2L891.4 233.6L912.9 196.1C920.4 182.9 933.1 180.7 943 186.3C952.6 191.8 957.1 203.7 949.4 217.3C948.5 218.9 947.3 220.7 946.2 221.9C949.2 229.3 948.9 238.7 942.5 250C940.8 252.9 938.8 255.9 936.2 259L926.3 253.3ZM939.4 212.4C944.6 204.2 941.9 197.8 937.7 195.5C933.1 192.9 927.1 193.9 923 201.2L919.2 207.9C923.6 205.6 929.1 205.8 934.5 208.9C936.2 209.7 937.9 211 939.4 212.4ZM933.3 220.8C932.1 219.4 930.7 218.3 929.2 217.5C926.2 215.8 923.5 216.3 922.2 218.5C921.3 220.2 921.9 222 923.7 223.1C926.2 224.4 929.7 224.8 933.3 220.8Z"
				fill="fill-inherit"
			/>
		</svg>
	)
}
