import { SVGProps, useState } from 'react'
import { LoadingIndicator } from './LoadingIndicator'

function AddToWalletButton(properties: JSX.IntrinsicAttributes & SVGProps<SVGSVGElement>) {
	return (
		<svg id="addtoapplewallet" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 110.739 35.016" height={40} shapeRendering="geometricPrecision" textRendering="geometricPrecision" {...properties}>
			<defs>
				<path
					id="addtoapplewallet-u-svgid_1_"
					d="M33.265,23.054v.265c0,.127,0,.254-.001.381-.001.107-.002.214-.005.321-.006.233-.02.468-.061.698-.042.234-.111.452-.219.664-.106.209-.245.4-.411.565-.166.166-.357.304-.565.411-.212.108-.43.177-.664.219-.23.041-.465.055-.698.061-.107.003-.214.004-.321.005-.127.001-.254.001-.381.001l-1.459-.001l1.358.001h-18.853l1.358-.001-1.459.001c-.127,0-.254,0-.381-.001-.107-.001-.214-.002-.321-.005-.233-.006-.468-.02-.698-.061-.234-.042-.451-.111-.664-.219-.209-.106-.4-.245-.565-.411-.166-.166-.304-.357-.411-.565-.108-.212-.177-.43-.219-.664-.041-.23-.055-.465-.061-.698-.003-.107-.004-.214-.004-.321-.001-.127-.001-.254-.001-.381v-.208-12.212v1.25-1.458c0-.127,0-.254.001-.381.001-.107.002-.214.005-.321.006-.233.02-.468.061-.698.042-.234.111-.452.219-.664.106-.209.245-.4.411-.565.166-.166.357-.304.565-.411.212-.108.43-.177.664-.219.23-.041.465-.055.698-.061.107-.003.214-.004.321-.005.127-.001.254-.001.381-.001h1.458h17.496.1c.127,0,.254,0,.381.001.107.001.214.002.321.005.233.006.468.02.698.061.234.042.451.111.664.219.209.106.4.245.565.411.166.166.304.357.411.565.108.212.177.43.219.664.041.23.055.465.061.698.003.107.004.214.005.321.001.127.001.254.001.381v.265v12.098Z"
				/>
			</defs>
			<g id="addtoapplewallet-s-g1">
				<g id="addtoapplewallet-s-g2">
					<path
						id="addtoapplewallet-s-path1"
						d="M100.211,0L9.533,0c-.367,0-.729,0-1.094.002-.307.002-.61.008-.919.013-.666.016-1.339.057-2.004.176-.669.118-1.291.318-1.9.627-.599.307-1.146.705-1.619,1.179-.478.473-.875,1.022-1.178,1.621-.311.608-.508,1.233-.625,1.903-.12.662-.162,1.331-.179,2.001-.009.307-.01.614-.015.921c0,.362,0,.725,0,1.092v14.948c0,.369,0,.73,0,1.094.005.31.006.611.015.922.017.67.059,1.34.179,2.002.117.67.314,1.298.625,1.904.303.596.7,1.144,1.178,1.614.473.477,1.02.875,1.618,1.178.609.312,1.231.51,1.901.631.664.119,1.338.158,2.004.177.309.007.613.011.919.011.366.002.728.002,1.095.002h90.678c.359,0,.724,0,1.084-.002.305,0,.617-.004.922-.011.67-.018,1.342-.058,2-.177.67-.121,1.293-.318,1.908-.631.597-.304,1.144-.701,1.617-1.178.476-.47.873-1.018,1.181-1.614.307-.606.506-1.234.619-1.904.123-.662.162-1.332.186-2.002.004-.311.004-.611.004-.922.008-.363.008-.724.008-1.094v-14.949c0-.366,0-.729-.008-1.092c0-.307,0-.614-.004-.921-.023-.67-.062-1.34-.186-2.002-.113-.67-.312-1.295-.619-1.903-.309-.599-.705-1.147-1.181-1.621-.473-.474-1.019-.872-1.617-1.179-.615-.31-1.238-.51-1.908-.627-.658-.119-1.33-.16-2-.177-.305-.005-.617-.011-.922-.013-.36.001-.725.001-1.085.001v0Z"
						fill="#a6a6a6"
					/>
				</g>
				<path
					id="addtoapplewallet-s-path2"
					d="M100.211,0.65c.358,0,.722,0,1.08.002.246.002.498.006.746.01l.164.003c.529.013,1.222.044,1.904.167.619.108,1.184.294,1.723.565.539.277,1.028.633,1.455,1.061.423.42.78.91,1.059,1.452.271.539.454,1.101.56,1.729.117.63.153,1.288.175,1.891.004.304.004.609.004.926.008.358.008.716.008,1.078v14.948c0,.364,0,.721-.008,1.094c0,.308,0,.606-.004.899-.022.617-.058,1.275-.177,1.916-.105.622-.288,1.184-.555,1.713-.284.549-.641,1.037-1.066,1.456-.423.427-.91.782-1.449,1.056-.531.27-1.097.456-1.729.571-.655.119-1.329.151-1.898.167-.3.007-.607.011-.911.011-.358.002-.722.002-1.08.002h-90.679c-.365,0-.726,0-1.095-.002-.301,0-.6-.004-.901-.01-.572-.016-1.245-.048-1.906-.167-.633-.114-1.196-.301-1.722-.571-.539-.273-1.027-.629-1.457-1.063-.422-.415-.777-.901-1.055-1.447-.268-.523-.453-1.086-.564-1.724-.124-.681-.155-1.374-.169-1.904-.006-.208-.009-.411-.011-.616l-.003-.287v-1.094-14.948-1.081l.004-.299c.002-.204.005-.408.011-.615.013-.528.045-1.221.17-1.907.11-.63.294-1.192.564-1.721.275-.543.63-1.032,1.058-1.455.426-.427.916-.783,1.453-1.058.536-.273,1.098-.458,1.721-.567.684-.123,1.378-.153,1.899-.167l.198-.003c.239-.004.476-.008.714-.009.364-.002.726-.002,1.091-.002h90.678"
				/>
				<g id="addtoapplewallet-u-walleticon">
					<g id="addtoapplewallet-s-g3" clipPath="url(#addtoapplewallet-u-svgid_2_)">
						<rect id="addtoapplewallet-s-rect1" width={24.099001} height={16.780001} rx={0} ry={0} transform="translate(8.352 8.169)" fill="#dedbce" />
						<rect id="addtoapplewallet-s-rect2" width={23.563999} height={9.997} rx={0} ry={0} transform="translate(8.63 8.436)" fill="#40a5d9" fillRule="evenodd" />
						<g id="addtoapplewallet-s-g4">
							<image
								id="addtoapplewallet-s-image1"
								width={109}
								height={52}
								xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAHEAAAA6CAYAAACH4bZ/AAAACXBIWXMAAC4jAAAuIwF4pT92AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAArlJREFUeNrsnG1vEkEUhXeXBSpUFCW+6zdN/P8/qGk00WhFY6CWsqD3JmfS6bpLoWGXNnlOckJ32NkPPNw7MzT3JglCCKE9KN3zfWh/+rsPiGn0Ghs1D6/sW0H08czckXNdZ4BsHODavDIXel3dBDOvGAuwuuYj8wO5r/uJyGYBOryF+Vy+0Fhtis1rItCBHZvH5qfmx7ruKzJZJ5tZ/wLA3+Yz+ZdgFttATKMIdGDPzW/lFwJ6JIgAbAZkIWAO75P5RO95hP6pS6vlSOwI1FjwPprfm18pGoHYDsRv5pHGF/IyWh9rIWa6Hpgn5ncC+MH80vzQ3GNNbBTiSmvgSDzm5p9KqXOB/C8a89IxIqRSh/ja/EZROFEU5gBsVGttIjOlz+/mz+YvArnQPbUQO4q0Y21mngneSA/u6eGo2Wh0FkMtXxOxGEWbyuWmdBrWQ5/wRB5FEcgZsR2FYAogx1rK4v1IGkdjVorEvib45EdaH7sAbE1picVAPIYCW7mpzCrS6VATB5smokZBhk1mTzC7m35oyUrfgFwT+noAafTwEdkppdGkDuLOE1FrIG/850N224no7ogjAxAREBEQERCBiICIgIiACEQERAREBEQgIiAiICIgAhEBEQERARGICIgIiAiIQET3RXnN+M4NcdDetPNnn1c8YJ1cb4gTeqhQl9EexPC5ryIeWzUjCgC9nNiL/73QfxbdB8R2Ic7E4EJMakHmJYCXmjg1fxU4Lzal0LRdiB6B52IwFZPLOpB5ib53bPBGOKca934qoVYctafQCmUqFmdiU2wDcSZwiR7gnTS6CbWKh9jYLMXjh5jMIojXQMbtMP244SXe3u5kmFw1XSCVHi6lLpVW54rEypSalv4OBf/B1OsfFuQ62qkWdWtiWnFNufc9OzNualqL7hZMhBBCzeqfAAMARUSzZ7uCWKkAAAAASUVORK5CYII="
								preserveAspectRatio="xMidYMid meet"
								transform="matrix(.24 0 0 0.24 7.4439 9.1078)"
								opacity={0.17}
							/>
							<g id="addtoapplewallet-s-g5">
								<path
									id="addtoapplewallet-s-path3"
									d="M32.194,12.688c0-.081,0-.161,0-.242c0-.068-.001-.136-.003-.204-.004-.148-.013-.297-.039-.443-.027-.148-.07-.286-.139-.421-.067-.132-.155-.254-.26-.359s-.226-.193-.359-.261c-.135-.069-.273-.112-.421-.139-.146-.026-.295-.035-.443-.039-.068-.002-.136-.003-.204-.003-.081,0-.161,0-.242,0h-.066-18.353-.925c-.081,0-.161,0-.242,0-.068,0-.136.001-.204.003-.148.004-.297.013-.443.039-.148.027-.286.07-.421.139-.132.067-.254.155-.359.261-.105.105-.193.226-.26.359-.069.135-.112.273-.139.421-.026.146-.035.295-.039.443-.002.068-.003.136-.003.204c0,.081,0,.161,0,.242v.925-.727v7.689h23.564v-7.64-.247Z"
									clipRule="evenodd"
									fill="#ffb003"
									fillRule="evenodd"
								/>
							</g>
						</g>
						<g id="addtoapplewallet-s-g6">
							<image
								id="addtoapplewallet-s-image2"
								width={109}
								height={52}
								xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAHEAAAA6CAYAAACH4bZ/AAAACXBIWXMAAC4jAAAuIwF4pT92AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAqFJREFUeNrsnNtu00AURW3HdkJKUxAX8YAqUN/4//9BCCQQlIBQUtrGSZkj7ZGmxpNLZU9baS1pK7Xr8UNWzxkn1UyWAQBAD+Q9XQP9c9OHxLwjkEZeOwdL9MJGSqnXApFJBG5c1i6NXte7ZJYRgXZ+4jJVxjqHyOEFmrwrlwvlUueiLbZLYilxz1xeKrNAJPPkcPOfF/jH5Vz5LZnNPhJ9C51I4FuXdy6nEjnV9QgcTmQjYSbvs8tH/c4q9G+srXZJNFmvXN67fHA5c3mNxKQSv6v7ZapMyyqYHzsl5prvKpcjVd6pBJ7peCLJSBxO4lpz4Ew+li6/1FKXEvlfNZaR+fC5qu+NqtLaa60bw3BY23yi99na5w+XLy5fJfJK19xsq8RaEk8kbqbjWlVKFQ5fjbm6oX+wfBE8WI5UjdF2OpKspxo0019FFbRRJA6P9+BFWlc8bk1neViNRWvwWBJPgoElApMRftEyVhc8ltA69kxSdFTiVCKn2wbCoCILFU8tmdW2Yio6vqmp9hkIySpytGs6K+46EJKJ3PkPiOKuA+HhwOc+JAISAYmARCQCEgGJgEQkAhIBiYBEJAISAYmARCQCEgGJgEQkAhIBiYBEJMJjoYycP3hDHOiNg9/7suMGm+z2hjh+DxXWZaST6N/3deBjr82IvEBbTmyL/22h/yK4DolpJS7k4FJOoiLLlsBrDZy7fJM4W2zKQtO0Eq0CL+RgLifXMZFly77t2GAb4XzSedtPxa8Vh3T4rVDmcnEuN80+EhcSl+kGtuzb75pBJaZ9sFnJx085WQQSb4nMg1e/BYrtmHGkNlrRSu+1pa7UVpeqxM6Wmrd+9gv+fdhV8X5FboIn1SY2J+Ydxyz3fmSfGWOSkPfwZAIAwLD8E2AAOBuy81Gw3f4AAAAASUVORK5CYII="
								preserveAspectRatio="xMidYMid meet"
								transform="matrix(.24 0 0 0.24 7.4439 11.2678)"
								opacity={0.16}
							/>
							<g id="addtoapplewallet-s-g7">
								<path
									id="addtoapplewallet-s-path4"
									d="M32.194,14.83c0-.081,0-.161,0-.242c0-.068-.001-.136-.003-.204-.004-.148-.013-.297-.039-.443-.027-.148-.07-.286-.139-.421-.067-.132-.155-.254-.26-.359s-.226-.193-.359-.261c-.135-.069-.273-.112-.421-.139-.146-.026-.295-.035-.443-.039-.068-.002-.136-.003-.204-.003-.081,0-.161,0-.242,0h-.067-18.352-.925c-.081,0-.161,0-.242,0-.068,0-.136.001-.204.003-.148.004-.297.013-.443.039-.148.027-.286.07-.421.139-.132.067-.254.155-.359.261-.105.105-.193.226-.26.359-.069.135-.112.273-.139.421-.026.146-.035.295-.039.443-.002.068-.003.136-.003.204c0,.081,0,.161,0,.242v.925-.727v7.689h23.564v-7.64-.247Z"
									clipRule="evenodd"
									fill="#40c740"
									fillRule="evenodd"
								/>
							</g>
						</g>
						<g id="addtoapplewallet-s-g8">
							<image
								id="addtoapplewallet-s-image3"
								width={109}
								height={52}
								xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAHEAAAA6CAYAAACH4bZ/AAAACXBIWXMAAC4jAAAuIwF4pT92AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAvBJREFUeNrsnFtv00AQhX1NgkkvEYW+ICQET/z//4NASEBbUi5JyMVOmJHOiMF4mzRJnVQ6RzrazdbrB3+and1KO1FEURRFUZQqvsczcaBP7aYV3NTfGaKBSlybwokbp3YDuIQreOnGV5vAjO+AZ8BycSbuwF20GSNyLxFYiufiGdo5xhYO7J0wswBAHe+JC/FTtNbvo98B5IggtwIYAZBCm4hH4jH6E9efAmpwiW2CmAHSufhC/Fw8EJ+JT9H2EZEpAe4EskIEKsAf4p9ob8XX4hvxd8AsN4FoS2gPAF+KX4tfiV9gTCGeuEhkXtw9H1ok/gJEhXYl/ih+j2f1ud+hZbUJYoHoU4DvxG/FlwD4BJCZE/efE6cApSC/IlgiROrM5cggRNt95sh7F4hABfgGvwu3yUkJcK8gK7eZmSBdxciLt4jOMf7+XzRmgXw4wBJ6CYDnyIGJg0eA+wNpu/8l2hhRqXnxk/gzQM7c0SMYiR1APHM5sABALp8P+w+XFRhEWA1tY/kMHGwjuai/IKnlww5C+dTlwLwWgdTDwfQcDOQA+bEXSmOJ66eg3Uck2kRG4GFAdrEKngBoJ3Ska4rEouFAT4Dtgkxcnuy6DWW8DqJtbPJNJlKtRWS67jSQbDuRag3k2tNAsu1E6niU8BMQIkWIFCFShEiIFCFShEgRIiFShEgRIkWIhEgRIkWIFCESIkWIFCFShEiIFCFShEgRIiFSj0RZYHzVYKod3fvbZw0v8BWOyuhvDRXey2gPon33yvHYqBiRAdTrxFrJQS/6j9xzhNguRCtONAWTIMisBnCOiUPxF4DTy6a8aNouRI3ACRgMwWQeApnV6GvFBq1i9AHjWhTH7opT7alCBA7B4gZsyk0gjgAuwgv02nce8a7iITY2C/D4BiYjB/EfkL5+qZVA0YoZVpQv51J60CXVihONEYmNS2pc69uFfzNrtx0W5NLtVMtQTowbfvO69yM7M4YgEd7xwaQo6qj1R4ABAM5x0/sxgGqpAAAAAElFTkSuQmCC"
								preserveAspectRatio="xMidYMid meet"
								transform="matrix(.24 0 0 0.24 7.4439 13.4278)"
								opacity={0.17}
							/>
							<g id="addtoapplewallet-s-g9">
								<path
									id="addtoapplewallet-s-path5"
									d="M32.194,16.972c0-.081,0-.161,0-.242c0-.068-.001-.136-.003-.204-.004-.148-.013-.297-.039-.443-.027-.148-.07-.286-.139-.421-.067-.132-.155-.254-.26-.359s-.226-.193-.359-.261c-.135-.069-.273-.112-.421-.139-.146-.026-.295-.035-.443-.039-.068-.002-.136-.003-.204-.003-.081,0-.161,0-.242,0h-.067-18.352-.925c-.081,0-.161,0-.242,0-.068,0-.136.001-.204.003-.148.004-.297.013-.443.039-.148.027-.286.07-.421.139-.132.067-.254.155-.359.261-.105.105-.193.226-.26.359-.069.135-.112.273-.139.421-.026.146-.035.295-.039.443-.002.068-.003.136-.003.204c0,.081,0,.161,0,.242v.925-.727v7.689h23.564v-7.64-.247Z"
									clipRule="evenodd"
									fill="#f26d5f"
									fillRule="evenodd"
								/>
							</g>
						</g>
						<path
							id="addtoapplewallet-s-path6"
							d="M7.202,7.008v11.068h1.428v-6.605-.925c0-.081,0-.161,0-.242c0-.068.001-.136.003-.204.004-.148.013-.297.039-.443.027-.148.07-.286.139-.421.067-.132.155-.254.26-.359.106-.104.227-.192.359-.259.135-.069.273-.112.421-.139.146-.026.295-.035.443-.039.068-.002.136-.003.204-.003.081,0,.161,0,.242,0h.925h18.348.071c.081,0,.161,0,.242,0c.068,0,.136.001.204.003.148.004.297.013.443.039.148.027.286.07.421.139.132.067.254.155.359.261.105.105.193.226.26.359.069.135.112.273.139.421.026.146.035.295.039.443.002.068.003.136.003.204c0,.081,0,.161,0,.242v.925v6.605h1.428v-11.07h-26.42Z"
							fill="#d9d6cc"
							fillRule="evenodd"
						/>
						<g id="addtoapplewallet-s-g10">
							<image
								id="addtoapplewallet-s-image4"
								width={121}
								height={53}
								xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAH0AAAA6CAYAAACd3VbxAAAACXBIWXMAAC4jAAAuIwF4pT92AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAABMZJREFUeNrsnQtz0kAUhTfJ8iog2LdI7VhH6/j//5DjjLa1D22hBQrEXT13uOwECBSTEO6ZObNIKWbvt3v3EdgqJRKJRCKRKA/y1vSaJBTmLK6pxc1bcOGu07roMOJxlhqC58RMpRwzHqvQjZs3pxK+sWb2U6iEvdAxPILpuTCiQaQF2mdlAPspxYzHbchMMYu8ILr4onHFuGq8Y1xAZbyEW6q94IFxH+WQNQD+eJwQfKo/B6wRnwLiVkKpU+jxIeLxbPxo3DV+Quz+gtczKqUBfN/40HjPuIZKJVEJAjfCxdLF27LHGsET3EMlR/8ZPNU9QCzKiBN5B52khsdFvDapcZ46io1Fx/jG+Mr4GsCfF0GvAfhH41PjXVQySLjF9lGBe5SPAP1g/Mv4DmUH8KdS2ZqB05BXRnyaiIstG8Z1lK/w81LC2ZE6io3DrfFXPNdDHG1svDg93QL/bPwGrTdIsNWOWU8n4GQL+qfxd/gSzz2iRa8TPAEvIAYW8pFxCz4E/Doz9fQkx3XqKDYGP/DcHeJEQ43SC8b0KipzbPwWrVcnnKqGgEhpfYCefo/U9Q29i2ehdYJ3gduhrm383vjM+ATQm/h5JcUxneLVweMrMJxqfDpGRctsnKonCJ2DHzkm8AfGr1kq9dnvdgH+JWO8xzIfAX9n/Mn4HNBbuIYdBlqzjJj0JG6Ix1WwK7jZRsdo4T6boeqEoVNFdMQSroaKVdjYqZzl3Zgt81aFHiB4TfRwC/wLyjYagru6SWtvI2RcZy4b9RKtPa3KeE6FAtYQNFs28dl+n83mexGbOsuk9RImZsfo2ecAfgrg1YjelOamzEJOWm2OvAUVi5r0DSI2dJYdx6uAa8fuD/CJs4z1Vba2Xudqk6AvAkPLuy6Wc138e8TWrnHAu++7izR+BrfxXHUTgW869Hk9kvf0sTOxG89I994SPXxjgecB+qyxt89SO80DbiJSfhix01Z0lmZ8pn6M/6O0qcDzAj1qls334gNAqmCXqst2p3ij0HgdpfSWM3Fr473LKeyyCfQ5kzxaT5MoA9CW6SV2qB6wzqd1fAGNoo419xHW42fYhOFLszRuogj0mDtnivX+BnbNLtS/mw+32NHr4XVlpG3bw/fZDmQLDaDJ1uL+JgPPG/SoiV3AxvoDwL5B+RtjvALQBqDvsbKJLFFW6X2mQKAvCd5nqbuBXsvv2PGeXlOTu2P0GYJyxE6bEujZH+P57dC6muzY9Z0xvaQmN0oKanorU+UFeJ6hc0j8/kER8N1P2/BPwQQsW+QK9jZAd+HTnj2l/Kh1uspTGt9m6G4DcG/gqLz2aIE+OwNsnXwlEugigS4S6CKBLhLoIoEuEugigS4S6CKBLhLoIoEuEugigS4S6CKBLhLo26g4H5eaefKgKHOKxUoveAN+WiOdPGjlSXwzC504jRi/MA50Ak6nOtlvenbY7wj0bEPvgBkdqjgFXs8BPsAv2u99XQB0kufIiVaDTufIXajJV7MHHLye01rsV3ntNzzp1EF7JlmSJ0aKVpN7YuQ1WA7jQO8AtMIbJHk2rOhlEzn3bNgOgx5m+RRo0ctS/MxToLN+3rtodfBLnffOwWfhLzuI1rxm36S/4SJaHb5o2/VHgAEAKq1w/7wLXkMAAAAASUVORK5CYII="
								preserveAspectRatio="xMidYMid meet"
								transform="matrix(.24 0 0 0.24 6.0039 15.3478)"
								opacity={0.14}
							/>
							<g id="addtoapplewallet-s-g11">
								<path
									id="addtoapplewallet-s-path7"
									d="M26.985,17.005c-.153,0-.307,0-.46.001-.129.001-.258.002-.387.006-.281.008-.565.024-.843.074-.282.051-.545.134-.801.264-.033.017-.738.337-1.372,1.323-.481.749-1.417,1.543-2.727,1.543s-2.246-.794-2.727-1.543c-.667-1.039-1.428-1.351-1.373-1.323-.256-.131-.519-.213-.801-.264-.278-.05-.562-.067-.843-.074-.129-.003-.258-.005-.387-.006-.153-.001-.307-.001-.46-.001h-6.6v9.996h26.42v-9.996h-6.639Z"
									clipRule="evenodd"
									fill="#dedbce"
									fillRule="evenodd"
								/>
							</g>
						</g>
						<clipPath id="addtoapplewallet-u-svgid_2_">
							<use id="addtoapplewallet-s-use1" width={25.705999} height={19.279997} xlinkHref="#addtoapplewallet-u-svgid_1_" />
						</clipPath>
					</g>
				</g>
				<g id="addtoapplewallet-s-g12">
					<g id="addtoapplewallet-s-g13">
						<path id="addtoapplewallet-s-path8" d="M41.23,17.661h1.351l2.926,8.057h-1.312l-.737-2.177h-3.105l-.743,2.177h-1.312l2.932-8.057Zm-.542,4.875h2.429l-1.2-3.54h-.022l-1.207,3.54Z" fill="#fff" />
						<path
							id="addtoapplewallet-s-path9"
							d="M46.28,19.844h1.206v1.011h.028c.352-.698,1-1.122,1.837-1.122c1.497,0,2.457,1.173,2.457,3.048v.006c0,1.871-.966,3.048-2.44,3.048-.832,0-1.508-.424-1.854-1.105h-.028v2.943h-1.206v-7.829Zm4.3,2.943v-.006c0-1.251-.598-2.015-1.541-2.015-.916,0-1.558.793-1.558,2.015v.006c0,1.218.648,2.01,1.558,2.01.948,0,1.541-.771,1.541-2.01Z"
							fill="#fff"
						/>
						<path
							id="addtoapplewallet-s-path10"
							d="M52.999,19.844h1.206v1.011h.028c.352-.698.999-1.122,1.837-1.122c1.497,0,2.457,1.173,2.457,3.048v.006c0,1.871-.966,3.048-2.44,3.048-.832,0-1.508-.424-1.854-1.105h-.028v2.943h-1.206v-7.829Zm4.299,2.943v-.006c0-1.251-.598-2.015-1.541-2.015-.916,0-1.558.793-1.558,2.015v.006c0,1.218.648,2.01,1.558,2.01.949,0,1.541-.771,1.541-2.01Z"
							fill="#fff"
						/>
						<path id="addtoapplewallet-s-path11" d="M59.59,17.661h1.207v8.057h-1.207v-8.057Z" fill="#fff" />
						<path
							id="addtoapplewallet-s-path12"
							d="M61.97,22.803v-.006c0-1.837,1.061-3.065,2.709-3.065s2.658,1.183,2.658,2.948v.408h-4.159c.022,1.111.631,1.758,1.591,1.758.715,0,1.19-.368,1.341-.809l.017-.045h1.144l-.011.062c-.189.932-1.095,1.781-2.518,1.781-1.727,0-2.772-1.178-2.772-3.032Zm1.224-.569h2.948c-.101-1.016-.67-1.513-1.457-1.513-.783,0-1.386.53-1.491,1.513Z"
							fill="#fff"
						/>
						<path
							id="addtoapplewallet-s-path13"
							d="M71.171,17.661h1.301l1.503,6.315h.022L75.7,17.661h1.183l1.703,6.315h.028l1.502-6.315h1.302L79.24,25.718h-1.2l-1.731-6.075h-.029l-1.737,6.075h-1.201l-2.171-8.057Z"
							fill="#fff"
						/>
						<path
							id="addtoapplewallet-s-path14"
							d="M81.761,24.048v-.011c0-1.022.794-1.647,2.184-1.731l1.596-.095v-.441c0-.647-.419-1.039-1.167-1.039-.698,0-1.128.33-1.228.799l-.012.051h-1.138l.005-.062c.084-1.011.961-1.787,2.407-1.787c1.435,0,2.345.76,2.345,1.937v4.048h-1.212v-.927h-.022c-.341.631-1.021,1.033-1.798,1.033-1.172.001-1.96-.719-1.96-1.775Zm2.289.811c.848,0,1.491-.575,1.491-1.341v-.458l-1.435.09c-.725.044-1.128.362-1.128.859v.012c0,.513.425.838,1.072.838Z"
							fill="#fff"
						/>
						<path id="addtoapplewallet-s-path15" d="M88.074,17.661h1.206v8.057h-1.206v-8.057Z" fill="#fff" />
						<path id="addtoapplewallet-s-path16" d="M90.979,17.661h1.206v8.057h-1.206v-8.057Z" fill="#fff" />
						<path
							id="addtoapplewallet-s-path17"
							d="M93.245,22.803v-.006c0-1.837,1.06-3.065,2.708-3.065s2.659,1.183,2.659,2.948v.408h-4.16c.022,1.111.631,1.758,1.592,1.758.715,0,1.189-.368,1.34-.809l.018-.045h1.144l-.011.062c-.19.932-1.095,1.781-2.519,1.781-1.727,0-2.771-1.178-2.771-3.032Zm1.223-.569h2.949c-.101-1.016-.67-1.513-1.458-1.513-.782,0-1.385.53-1.491,1.513Z"
							fill="#fff"
						/>
						<path
							id="addtoapplewallet-s-path18"
							d="M100.142,24.171v-3.367h-.844v-.961h.844v-1.53h1.233v1.53h1.1v.961h-1.1v3.294c0,.598.269.776.776.776.129,0,.234-.012.324-.022v.932c-.14.022-.368.05-.614.05-1.161.001-1.719-.49-1.719-1.663Z"
							fill="#fff"
						/>
					</g>
					<g id="addtoapplewallet-s-g14">
						<g id="addtoapplewallet-s-g15">
							<path id="addtoapplewallet-s-path19" d="M42.193,12.483h-2.408l-.608,1.733h-.793l2.232-6.054h.747l2.232,6.054h-.793l-.609-1.733Zm-2.185-.642h1.964l-.949-2.702h-.067l-.948,2.702Z" fill="#fff" />
							<path
								id="addtoapplewallet-s-path20"
								d="M44.074,11.954c0-1.418.751-2.341,1.901-2.341.629,0,1.162.298,1.418.793h.063v-2.509h.722v6.318h-.688v-.722h-.067c-.285.503-.822.801-1.448.801-1.158.001-1.901-.918-1.901-2.34Zm.747,0c0,1.057.495,1.691,1.322,1.691.822,0,1.33-.646,1.33-1.691c0-1.036-.512-1.691-1.33-1.691-.823,0-1.322.638-1.322,1.691Z"
								fill="#fff"
							/>
							<path
								id="addtoapplewallet-s-path21"
								d="M49.342,11.954c0-1.418.751-2.341,1.901-2.341.629,0,1.162.298,1.418.793h.063v-2.509h.722v6.318h-.688v-.722h-.068c-.285.503-.822.801-1.448.801-1.158.001-1.9-.918-1.9-2.34Zm.746,0c0,1.057.495,1.691,1.322,1.691.822,0,1.33-.646,1.33-1.691c0-1.036-.512-1.691-1.33-1.691-.823,0-1.322.638-1.322,1.691Z"
								fill="#fff"
							/>
							<path
								id="addtoapplewallet-s-path22"
								d="M58.274,8.522v1.171h1.008v.604h-1.008v2.56c0,.533.202.759.672.759.13,0,.201-.004.336-.017v.608c-.144.025-.281.042-.424.042-.931,0-1.305-.344-1.305-1.208v-2.744h-.729v-.604h.729v-1.171h.721Z"
								fill="#fff"
							/>
							<path
								id="addtoapplewallet-s-path23"
								d="M60.072,11.954c0-1.456.798-2.341,2.086-2.341s2.085.885,2.085,2.341c0,1.451-.797,2.341-2.085,2.341s-2.086-.89-2.086-2.341Zm3.424,0c0-1.074-.483-1.691-1.339-1.691s-1.339.617-1.339,1.691c0,1.07.482,1.691,1.339,1.691.856,0,1.339-.621,1.339-1.691Z"
								fill="#fff"
							/>
						</g>
					</g>
				</g>
			</g>
		</svg>
	)
}

export function AddToAppleWalletButton({ setIsAddedToWallet, ...properties }: { setIsAddedToWallet: (isAdded: boolean) => void }) {
	const [isLoading, setIsLoading] = useState(false)

	async function generatePass() {
		if (isLoading) return
		setIsLoading(true)

		const { couponId } = await fetch('/api/coupon', {
			method: 'POST',
			headers: new Headers({
				'Content-Type': 'application/json',
				Accept: 'application/json',
			}),
			body: JSON.stringify({ initialWalletType: 'APPLE_WALLET' }),
		}).then((response) => response.json())

		if (!couponId) {
			console.error('No couponId returned')
			setIsLoading(false)
			return
		}

		const requestBody = {
			couponId,
			passType: 'APPLE_WALLET',
		}

		await fetch('/api/pass', {
			method: 'POST',
			headers: new Headers({
				'Content-Type': 'application/json',
				Accept: 'application/json',
			}),
			body: JSON.stringify(requestBody),
		})
			.then((response) => response.json())
			.then((data) => {
				if (!data.passUrl) {
					setIsLoading(false)
					return
				}

				// Redirect user to data.passUrl
				window.location.href = data.passUrl

				setTimeout(() => {
					setIsAddedToWallet(true)
					setIsLoading(false)
				}, 1500)
			})
			.catch((error) => {
				setIsLoading(false)
				console.error(error)
			})
	}

	return (
		// If loading, show loading spinner. Else show button.
		isLoading ? (
			<LoadingIndicator />
		) : (
			<a
				id="add-to-apple-wallet"
				href="#"
				onClick={() => {
					if (isLoading) {
						return
					}
					generatePass()
				}}>
				<AddToWalletButton {...properties} />
			</a>
		)
	)
}
